@import "../../containers/Main/Variables.scss";
.Preloader{
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}

.preloaderLogo{
  width: 80px;
  fill: $color-primary;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 80px;
  animation: spinLoader 1.2s infinite linear;
  will-change: transform;
}
