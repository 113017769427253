$defaultFont: 'Arial';
$defaultColorCheckbox: #E9EAF0;
$defaultColor: #7E8FA4;
$tableColorIcons: #6C7B8A;
$dotsColor: #B5B5BE;
$extraBlack: #282835;
$mainTransition: .2s all ease;
$defaultPadding: 0 20px;
$defaultBorderRadius: .3rem;
$cardBorderRadius: .8rem;
$colorViolet: #B620E0;
$mainCollapse: #B2BECD;
$orderIcon: #65757b;
$itemCount: #212529;
$borderColor: #D8DCE6;
$font-small: 14px;
$font-medium: 16px;
$font-large: 18px;

$color-primary-light: #E5F1FF;
$color-primary: #007BFF;

$color-blue-light: #E5F6FD;
$color-blue: #3AABEE;

$color-secondary-light: #F5F6FA;
$color-secondary: #73849A;

$color-success-light: #E2F6E2;
$color-success: #21BA46;

$color-danger-light: #FFECEC;
$color-danger: #FC5A5A;

$color-warning-light: #FDF1E5;
$color-warning: #FD810F;

$color-violet-light: #F3F1FF;
$color-violet: #6160E9;
