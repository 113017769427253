@import '../containers/Main/Variables.scss';

.sidebarLinks {
  height: fit-content;
  width: 100% !important;
  min-width: unset !important;
  max-width: unset !important;
  flex: unset !important;
  background: $color-secondary-light;

  .ant-menu {
    background: #fff;
    padding: 10px 0;
    margin-bottom: 15px;
    border-radius: $cardBorderRadius;
  }

  li {
    padding: 0 20px;
    font-size: 16px;
    margin: 0 !important;
  }

  .ant-menu-submenu-open svg {
    transform: rotate(180deg);
  }

  .ant-menu-submenu-title {
    font-size: 16px;
    padding-left: 20px !important;
    color: $color-secondary;

    p {
      display: flex;
      align-items: center;
    }

    .ant-menu-submenu-arrow {
      display: none;
    }

    .MainIcon {
      margin-left: auto;
    }

    svg {
      transition: $mainTransition;
      width: 16px;
      height: 16px;
      fill: $mainCollapse;
      margin-left: auto;
    }
  }

  .activeLink {
    div {
      span,
      a {
        color: $color-primary;
      }
    }

    div:after,
    div:before {
      opacity: 1;
    }
  }

  .sidebarLinksItem {
    position: relative;

    &::before {
      opacity: 0;
      transition: 0.2s all ease;
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      background: $color-primary;
      left: 0;
      z-index: 2;
    }

    &::after {
      content: none;
    }

    &:hover {
      color: $color-secondary;
      background: $color-secondary-light;
    }

    &.activeLink {
      background: $color-primary-light;
      span,
      a {
        color: $color-primary;
      }

      &:after,
      &:before {
        opacity: 1;
      }
    }

    &.ant-menu-item-selected {
      background-color: #fff;
      color: inherit;
    }
  }

  a {
    position: relative;
    transition: $mainTransition;
    display: block;

    div {
      display: block;
      position: relative;

      span {
        position: relative;
        z-index: 2;
      }
    }

    div:after {
      opacity: 0;
      transition: $mainTransition;
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 100%;
      background: $color-primary-light;
      left: -20px;
      z-index: 1;
    }

    div:before {
      opacity: 0;
      transition: 0.2s all ease;
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      background: $color-primary;
      left: -20px;
      z-index: 2;
    }
  }
}

.ant-pagination-item {
  border-radius: 6px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 6px;
}

.ant-pagination-options {
  display: none;
}
